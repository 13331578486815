<template>
  <div class="container">
    <nav class="navigation">
      <div v-if="me" class="menu">
        <template v-if="isDoerAndBigger">
          <router-link class="navigation__item" :to="{ name: 'time-log' }">
            Time Log
            <sup v-if="isAdmin" class="showStatus">D</sup>
          </router-link>
        </template>
        <template v-if="isManagerAndBigger">
          <router-link class="navigation__item" to="/analytics">
            Analytics
            <sup v-if="isAdmin" class="showStatus">M</sup>
          </router-link>
        </template>
        <template v-if="isManagerAndBigger">
          <router-link v-if="isAdmin" class="navigation__item" :to="{ name: 'settings' }">
            Settings
            <sup v-if="isAdmin" class="showStatus">M</sup>
          </router-link>

          <router-link v-else class="navigation__item" :to="{ name: 'clients-settings-overview' }">
            Settings
          </router-link>
        </template>
        <template v-if="isManagerAndBigger">
          <router-link class="navigation__item" :to="{ name: 'expenses' }">
            PPC/CW/LB
            <sup v-if="isAdmin" class="showStatus">M</sup>
          </router-link>
        </template>
        <template v-if="isManagerAndBigger">
          <router-link class="navigation__item" :to="{ name: 'accounting' }">
            Accounting
            <sup v-if="isAdmin" class="showStatus">M</sup>
          </router-link>
        </template>
        <template v-if="isDoerAndBigger">
          <router-link class="navigation__item" :to="{ name: 'lead-center' }">
            Lead Center
            <sup v-if="isAdmin" class="showStatus">D</sup>
          </router-link>
        </template>
        <template v-if="isDoerAndBigger">
          <router-link class="navigation__item" :to="{ name: 'tools' }">
            Tools
            <sup v-if="isAdmin" class="showStatus">D</sup>
          </router-link>
        </template>
      </div>
      <div v-if="me" class="reset-local-storage">
        <button @click="resetLocalStorage" class="button-outline-blue">Reset Local Storage</button>
      </div>
      <div v-if="me" class="account">
        <div class="navigation__item account__item">
          {{ me.full_name }}
        </div>
        <svg
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.5L4.5 11L12 18.5L10.5 21.5L0 11L10.5 0.5L12 3.5Z"
            fill="#848484"
          />
        </svg>
        <div class="account__menu account-menu">
          <router-link class="account-menu__item" :to="{ name: 'profile' }">Profile</router-link>
          <router-link class="account-menu__item" :to="{ name: 'applications-settings' }">App Settings</router-link>
          <a href="/logout" class="account-menu__item">Logout</a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TheNavigation',
  mounted() {
    this.setActiveNav()
    window.addEventListener('click', () => {
      setTimeout(() => {
        this.setActiveNav()
      }, 1)
    })
  },
  computed: {
    ...mapGetters(['me', 'isAdmin', 'isManagerAndBigger', 'isDoerAndBigger']),
  },
  methods: {
    ...mapMutations(['setMe']),

    resetLocalStorage() {
      localStorage.removeItem('managementTools')
      window.location.reload()
    },
    setActiveNav() {
      const oldActive = document.querySelector('.navigation__item.active')
      if (oldActive) {
        oldActive.classList.remove('active')
      }

      const pathName = window.location.pathname
      let currentLink

      if (pathName.includes('lead')) {
        currentLink = document.querySelector('[href="/lead-center/overview"]')
      } else if (pathName.includes('settings')) {
        currentLink = document.querySelector('[href="/settings"]')
      } else if (pathName.includes('accounting')) {
        currentLink = document.querySelector('[href="/accounting/overview"]')
      } else if (pathName.includes('expenses')) {
        currentLink = document.querySelector('[href="/expenses/backlink"]')
      }

      if (currentLink && !currentLink.classList.contains('router-link-active')) {
        currentLink.classList.add('active')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  font-size: 20px;
  padding: 15px 0 15px;
  display: flex;
  align-items: center;
  color: $text-secondary;
  &__item + &__item {
    margin-left: 20px;
  }
  &__item {
    font-weight: 500;
    transition: $transition;
    border-bottom: 1px solid transparent;
    padding-bottom: 2px;
    position: relative;
    cursor: pointer;

    &:last-child {
      &.router-link-active,
      &.active {
        &::after {
          width: 100%;
        }
      }
    }

    &:hover {
      color: $color-primary;
    }

    &.router-link-active,
    &.active {
      color: $color-primary;

      &::after {
        content: '';

        width: calc(100% - 5px);
        height: 1px;

        position: absolute;
        left: 0;
        bottom: -2px;

        background-color: $color-primary;
      }
    }
  }
}
.account {
  margin-left: 110px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
  &__item {
    padding-bottom: 0;
    font-weight: 400;
    color: $text-primary;
  }
  svg {
    margin-left: 8px;
    transform: rotate(-90deg);
    width: 18px;
    height: 14px;
    path {
      fill: $text-primary;
    }
  }
  &:hover {
    .account-menu {
      display: flex;
    }
  }
}
.account-menu {
  align-items: flex-start;
  display: none;
  padding: 12px 14px;
  position: absolute;
  flex-direction: column;
  background-color: #fff;
  font-size: 16px;
  width: calc(100% + 12px);
  top: 100%;
  left: -12px;
  z-index: 100;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, .15);

  &__item {
    font-size: 18px;
    transition: $transition;
    color: $text-primary;
    text-align: center;

    &:hover {
      color: $color-primary;
    }
  }
  &__item + &__item {
    margin-top: .75rem;
  }
}
.reset-local-storage {
  display: flex;
  margin-left: auto;
  margin-right: -90px;
}
</style>
