export default {
  state: {
    expChartsTagsVisible: {},
    efcChartsTagsVisible: {},
    pftChartsTagsVisible: {},
    expChartsMode: 'dollars'
  },
  mutations: {
    setExpChartTagsVisible(state, { key, value }) {
      state.expChartsTagsVisible[key] = value
    },
    setEfcChartTagsVisible(state, { key, value }) {
      state.efcChartsTagsVisible[key] = value
    },
    setPftChartTagsVisible(state, { key, value }) {
      state.pftChartsTagsVisible[key] = value
    },
    setExpChartsMode(state, { value }) {
      state.expChartsMode = value
    }
  },
  getters: {
    getExpChartTagsVisible: (state) => (key) => {
      return key in state.expChartsTagsVisible ? state.expChartsTagsVisible[key] : true
    },
    getEfcChartTagsVisible: (state) => (key) => {
      return key in state.efcChartsTagsVisible ? state.efcChartsTagsVisible[key] : true
    },
    getPftChartTagsVisible: (state) => (key) => {
      return key in state.pftChartsTagsVisible ? state.pftChartsTagsVisible[key] : true
    },
    getExpChartsMode: (state) => {
      return state.expChartsMode
    }
  }
}
