import { monthYear, yearMonthDay } from '@/helpers/dateFormats'
import dayjs from 'dayjs'
import Vue from 'vue'

export default {
  state: {
    searchQueries: {},
    requestYear: {},
    projectType: {},
    showArchived: {},
    showFilled: {},
    showErrorInvoices: {},
    fullScreenPages: {},
    agGridFilterChanged: {},
    agGridState: {},
    agGridRowsCount: {},
    showChartsPft: false,
    showChartsExp: false,
    showChartsEfc: false,
    selectedDateFrom: {},
    selectedDateTo: {}
  },
  mutations: {
    setShowChartsPft(state, value) {
      state.showChartsPft = value
    },
    setShowChartsExp(state, value) {
      state.showChartsExp = value
    },
    setShowChartsEfc(state, value) {
      state.showChartsEfc = value
    },
    updateSearchQuery(state, { page, query }) {
      Vue.set(state.searchQueries, page, query)
    },
    updateAgGridFilterChanged(state, { page, query }) {
      Vue.set(state.agGridFilterChanged, page, query)
    },
    updateAgGridState(state, { page, query }) {
      Vue.set(state.agGridState, page, query)
    },
    updateAgGridRowsCount(state, { page, query }) {
      Vue.set(state.agGridRowsCount, page, query)
    },
    setFullScreen(state, { page, query }) {
      Vue.set(state.fullScreenPages, page, query)
    },
    updateShowArchived(state, { page, query }) {
      Vue.set(state.showArchived, page, query)
    },
    updateShowFilled(state, { page, query }) {
      Vue.set(state.showFilled, page, query)
    },
    updateShowErrorInvoices(state, { page, query }) {
      Vue.set(state.showErrorInvoices, page, query)
    },
    updateRequestYear(state, { page, query }) {
      Vue.set(state.requestYear, page, query)
    },
    updateProjectType(state, { page, query }) {
      Vue.set(state.projectType, page, query)
    },
    setDefaultSelectedDates(state) {
      const now = dayjs()
      const tenthOfMonth = dayjs().set('date', 10)
      let subMonthsCount = 0
      if (now.isAfter(tenthOfMonth)) {
        subMonthsCount = 1
      }
      let startDate = dayjs('2021-01-01')
      let endDate = dayjs().startOf('month').subtract(1, 'M')
      state.selectedDateFrom = {
        label: startDate.format(monthYear),
        code: startDate.format(yearMonthDay)
      }
      state.selectedDateTo = {
        label: endDate.format(monthYear),
        code: endDate.endOf('month').format(yearMonthDay)
      }
    },
    setSelectedDateFrom(state, date) {
      state.selectedDateFrom = date
    },
    setSelectedDateTo(state, date) {
      state.selectedDateTo = date
    }
  },
  actions: {
    setSearchQuery({ commit }, payload) {
      commit('updateSearchQuery', payload)
    },
    setAgGridFilterChanged({ commit }, payload) {
      commit('updateAgGridFilterChanged', payload)
    },
    setAgGridState({ commit }, payload) {
      commit('updateAgGridState', payload)
    },
    setAgGridRowsCount({ commit }, payload) {
      commit('updateAgGridRowsCount', payload)
    },
    toggleFullScreen({ commit, getters }, { page }) {
      const currentValue = getters.isFullScreen(page)
      commit('setFullScreen', { page, query: !currentValue })
    },
    setShowArchived({ commit }, payload) {
      commit('updateShowArchived', payload)
    },
    setShowFilled({ commit }, payload) {
      commit('updateShowFilled', payload)
    },
    setShowErrorInvoices({ commit }, payload) {
      commit('updateShowErrorInvoices', payload)
    },
    setRequestYear({ commit }, payload) {
      commit('updateRequestYear', payload)
    },
    setProjectType({ commit }, payload) {
      commit('updateProjectType', payload)
    }
  },
  getters: {
    getShowChartsPft(state) {
      return state.showChartsPft
    },
    getShowChartsExp(state) {
      return state.showChartsExp
    },
    getShowChartsEfc(state) {
      return state.showChartsEfc
    },
    getSearchQuery: (state) => (page) => {
      return state.searchQueries[page] || ''
    },
    getAgGridFilterChanged: (state) => (page) => {
      return state.agGridFilterChanged[page] || ''
    },
    getAgGridState: (state) => (page) => {
      return state.agGridState[page] || ''
    },
    getAgGridRowsCount: (state) => (page) => {
      return state.agGridRowsCount[page] || ''
    },
    isFullScreen: (state) => (page) => {
      return state.fullScreenPages[page] || false
    },
    getShowArchived: (state) => (page) => {
      return typeof state.showArchived[page] == 'boolean' ? state.showArchived[page] : 'init'
    },
    getShowFilled: (state) => (page) => {
      return state.showFilled[page] || false
    },
    getShowErrorInvoices: (state) => (page) => {
      return state.showErrorInvoices[page] || ''
    },
    getRequestYear: (state) => (page) => {
      return state.requestYear[page] || new Date().getFullYear()
    },
    getProjectType: (state) => (page) => {
      return state.projectType[page] || 'SEO'
    }
  }
}
